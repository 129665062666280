import React, {useState, useEffect} from 'react';
import { Link } from 'react-router'; // For navigation links (optional)
import styled from 'styled-components';
import CallToAction from '../shared/CallToAction';
import {base} from '../Config/app';
import SignModal from '../sign/SignModal';
import {LangSwitcher} from '../Config/Lang/switcher';
import { ScrollTo } from '../shared/ViewControl';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #f5f5f5; /* Light gray background */
`;


const Logo = styled.img`
  height: 50px; /* Adjust logo height */
`;

const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 2rem;
  vertical-align: middle;

`;

const NavLink = styled.li`
  margin-right: 1rem;
  padding: 0 1rem;

  &:hover {
    /*background-color: #ccc; */
    border: 1px solid #aaa;
    border-radius: 5px;
  }
`;

const NavIcon = styled.li`
  margin-right: 1rem;

  &:hover {
    transform: scale(1.5);
  }
`;


const Header = ({lang, onLang}) => {
  const [showing, Showing] = useState(false)

  useEffect(()=>{
    //alert(showing)
  },[showing])

  return (
    <HeaderContainer>
      <Link to="/">
        <Logo src={base.logo} alt={base.title} />
      </Link>
      <LangSwitcher lang={lang} onLang={onLang} />
      <NavLinks>
        <ScrollTo to="about" label={'About'} />
        <ScrollTo to="plans" label={'Plans'} />
        <ScrollTo to="articles" label={'Articles'} />
        <ScrollTo to="contact" label={'Contact'} />
        <ScrollTo to="GetApp" label={'GetApp'} />
        <NavIcon style={{marginLeft:'1rem'}}>
          <Link to="https://linkedin.com/company/rais-digital-services" target="_blank" rel="noopener noreferrer">
            <img src="https://2us.ma/images/linkedin.png" alt="linkedin" title="linkedin" />
            </Link>
        </NavIcon>
        <NavIcon>
          <Link to="https://web.facebook.com/RaisDigitalServices" target="_blank" rel="noopener noreferrer">
            <img src="https://2us.ma/images/facebook.png" alt="facebook" title="facebook" />
            </Link>
        </NavIcon>

        <CallToAction name='Sign in' onClick={()=>Showing(true)} />

      </NavLinks>

{ showing ?
<SignModal 
    service={base.name}
    show={showing}
    logo={'images/sync-2.png'}
    onHide={()=>Showing(false)}
/>
: null}

    </HeaderContainer>
  );
};

export default Header;
