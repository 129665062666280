import React, {useState, useEffect} from 'react';
import {FormControl, Row, Col} from 'react-bootstrap';
import {NavLink} from 'react-router'; 
import {Navigation} from './Navigation';
import {base} from '../Config/app';
import {ProfileMenu} from '../Profile/Profile';

const Header = ({app, connected, onSearch}) => {

    const [profile, Profile] = useState('hidden')

    const onProfile = (e) => {
        //alert("onProfile")
        if(profile==='hidden') {
            const element = document.getElementById("ProfileItems")
            if(element) {
                //element.style.top = `${e.clientY+24}px`
                //element.style.left = `${e.clientX - 24}px`
                element.style.right = `5px`
            }
        }
        Profile( profile==='hidden' ? 'visible' : 'hidden')
    }
  
    const onChange = (e) => {
        //alert(e.target.value)
        onSearch(e.target.value)

        //this.forceUpdate()
        //console.log('Search',e.target.value, search)
    }

    useEffect(() => {
        onSearch('')
    }, [])    

    return (
        
        <div className="Header">
            <Row className="navbar-custom" expand="lg" style={{justifyContent:"center", display: "flex"}}>
                <Col sm={1}>
                    <NavLink to="/" >
                        <img className="Logo" src={base.logo} alt="Home" height="90px" padding="5px"  />
                    </NavLink>
                </Col>
                <Col  sm={6}>
                    <Navigation  connected={connected} />
                </Col>
                <Col  sm={1} style={{ textAlign: 'right', marginTop:'1rem' }}>
                    <i className="fa fa-search" style={{ fontSize:'1.5rem' }} onClick={onChange} ></i>
                </Col>
                <Col  sm={3}>
                    <FormControl 
                        className="d-inline p-2" 
                        style={{ textAlign: 'center', backgroundColor:'lightgray', marginTop:'1rem' }} 
                        name="Search" type="text" 
                        defaultValue=""
                        onChange={onChange}
                        placeholder="Search Items" />
                </Col>
                <Col  sm={1}>
                    <NavLink className="d-inline p-3 navbar-custom" to="/" style={{float:'right'}} onClick={onProfile} >Profile</NavLink>
                </Col>
            </Row>

            <ProfileMenu app={app} profile={profile} />

        </div>
        
    )
}

export default Header;