import React, {useState, useEffect} from 'react'
import './Quote.css'
import {useNavigate } from 'react-router'
import jsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import {base, quote} from '../Config/app';
import ContactInfo from '../Profile/ContactInfo';
import {OptionControl} from '../shared/InputControl';
import { addDays, toDate } from '../shared/Formating';
import Api, {Read} from '../shared/Api';
import {T as LS} from '../Config/Lang/switcher';
//import Uploader from '../shared/Uploader';

const Quote = ({service, lang, plan}) => {
  
  const currency = "DH"
  const todate = addDays(15)
  const [confirm, Confirm] = useState({ed:false, email:'', name:'', account:0, id:0})
  const [approved, Approve] = useState(false)
  const [quantity,Quantity] = useState({count:0, price:0})
  const [options , Options] = useState([])
  const [option , Option] = useState(1)
  const navigate = useNavigate()
  const T = (value, key='quote') => LS(lang, key, value)

  const order = T('order')

  /*
    const options = [
      {Id:1, Name:'1 month', Value:1},
      {Id:2, Name:'3 months', Value:3},
      {Id:1, Name:'6 months', Value:6},
      {Id:1, Name:'1 year (1 month free)', Value:11}]
*/
  const [etat, Etat] = useState('') // to debug !

useEffect(()=>{     
  window.scrollTo(0, 0)   
  if(plan && options.length===0) {
    Read(`/plan/options/${plan.Id}`, Options)
    //console.log(items)
  }
},[plan?.Id])


useEffect(()=>{

  //Etat(`Confirm ${confirm.id} ${confirm.account} ${confirm.name} ${confirm.email} ${confirm.ed}`)
},[confirm])


    const onSave = (e) => {
      SavePdf()
      return false;
    }

    const OnSubmit = (e) => {
      //e.preventDefault()
      e.target.disabled = true
      e.target.style.backgroundColor = 'gray'
      if(confirm.ed && confirm.id>0) {
        PageToData()
        .then(page => {
          const data = {
            ...confirm,
            service,
            category: "Quote",
            content: page,
            plan: plan.Id,
            price: quantity.price
          }
          //console.log(data, page);  //alert(`Confirming ${page.length} ${data.email} ${data.id}`);
    
          Api.post(`/plan/confirm`, data)
          .then(response => {
            Etat(` Confirmed ${response.data.message}`)
            //delete data['content']
          })
          .catch(error => {
            Etat(` Confirmed ${error.message} ?`) ;console.error(error)
          })
        })
      }
    }

    useEffect(()=>{     
      if(plan) {  
        Quantity({count:option, price: plan.Price*option}) 
        //alert(plan.Id)
      }
    },[plan])

    const toPrice = (price, ratio) => {
      return (price*ratio).toFixed(2);
    }

    const onOption = (e) => {
      const object = e?.target
      if(plan && object && object.value>0) {
        Option(object.value)
        e.stopPropagation()
      }
  }

  useEffect(()=>{     
    if(plan) {
      Quantity({count:option, price: plan.Price*option})
    }
  },[option])


    return (
plan ?
  <div className='Quote' id='container' style={{backgroundColor:quote.bc[plan.Id-2]}} >

<header>
    <img src={base.logo} alt="Logo" class="logo" onClick={(e) => {navigate('/')}}/>
    <div className="header-text">{T('title')}</div>
  </header>

  <div className="row">
    <div className="col-md-6">
      <div className="Address">
        <h5>Rais Digital Service</h5>
        <p>Res Zomorroda, Rte d'Imouzzer</p>
        <p>30050 Fez</p>
        <p>Morocco</p>
      </div>
    </div>

    <div className="col-md-6">
      <ContactInfo service={service} lang={lang} onConfirm={Confirm} /> 
    </div>
    {  etat.length>0 ? <p>{etat}</p> : null }
  </div>

  <table>
    <thead>
      <tr>
        { 
          quote.th.map(item=>
            <th style={{width:item.width}}>{item.name}</th>
          )
        }
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><h5>{plan.Name}</h5><p>{plan.Description}</p></td>
        <td>
          <OptionControl 
                    options={options} 
                    title={`${quote.th[1].name}= ${quantity.count}`} 
                    onChange={e => {onOption(e)}}
                    defaultValue={quantity.count} 
                    readOnly={approved} 
                />


        </td>
        <td className="Price">{quantity.count<11 ? plan.Price : quantity.price}</td>
        <td className="Price">{quantity.price} {currency}</td>
      </tr>
    </tbody>
    <tfoot style={{backgroundColor:'#aaa'}}>
      <tr>
        <th colspan="3">Total HT</th>
        <td className="Price">{quantity.price} {currency}</td>
      </tr>
      <tr>
        <th colspan="3">TVA (20%)</th>
        <td className="Price">{toPrice(quantity.price,0.2)} {currency}</td>
      </tr>
      <tr>
        <th colspan="3">Total TTC</th>
        <td className="Price" style={{fontWeight:'bold'}}>{toPrice(quantity.price,1.2)} {currency}</td>
      </tr>
    </tfoot>
  </table>

{ confirm.ed ?
    <div id="save-button" data-html2canvas-ignore="true">
      
      <div className='payment'>
        <p>{order[0]}</p>
        <button onClick={(e) => OnSubmit(e)} className="Button" style={{margin: "1rem auto"}}>{T('action')}</button>
        <p/><p>{order[1]}</p>
        <p>{order[2]}</p>
      </div>

        <button onClick={(e) => onSave(e)} className="Button">Save</button>
    </div>
:
  <div className='error'>
    <p>{T('recipient','contact')}</p>        
    <button onClick={() => window.print()} className="Button">Print</button>
  </div>
}
  <footer>
    <p>{T('valid')} : {todate}</p>
  </footer>

</div>
:
<div className='Quote' id='container' style={{backgroundColor:quote.bc[0]}} >
      <h1>{T('unselected')}</h1>
</div>

    )
}

const PageToData = () => {
  return new Promise(function (resolve, reject) {

    const element=document.querySelector('#container')
    html2canvas(element, { 
      useCORS: true,
    })
    .then((canvas)=>{
      const data = canvas.toDataURL('image/jpeg'); //console.log('ToData', data); alert(data.length)
      resolve(data)
    })
    .catch((error) => {
      reject(error)
    })
  })
}

function SavePdf(){
  const pdf = new jsPdf("p", "mm", "a4");
  const size = pdf.internal.pageSize;

  PageToData()
  .then(page => {
    pdf.addImage(page, 'JPEG',0,0, size.width, size.height)
    pdf.save(`Quote-${toDate()}.pdf`)
  })
}

function SavePdf0(){
  const pdf = new jsPdf("p", "mm", "a4");

  const width = pdf.internal.pageSize.getWidth();
  const height = pdf.internal.pageSize.getHeight();

	const element=document.querySelector('#container')
	html2canvas(element,{
    useCORS: true,
  })
  .then((canvas)=>{
    const img=canvas.toDataURL('image/jpeg') //; console.log('canvas.toDataURL', img)
    pdf.addImage(img, 'JPEG',0,0, width, height)
    pdf.save(`Quote-${toDate()}.pdf`)
  })
}

const saveAllPdf = () => {
    const pdf=new jsPdf()
	const domElements=document.querySelectorAll('')
	
	domElements.forEach((element,i)=>{

        html2canvas(element, { onclose: (document)=>{
              const buttons =  document.querySelector('#save-button')
              buttons.forEach((b,i)=>{
                b.style.visibility='hidden'
              })

            }})
        .then((canvas)=>{

        const img=canvas.toDataURL('image/jpeg')
        pdf.addImage(img,'JPEG',0,0,img.width,img.height)
        
        if(domElements.length===i+1){
            pdf.save('filename.pdf')
        }else{
            //doc.addPage()
        }
    })

    })
}

const SaveQuote = () => {
  const doc = new jsPdf(); // Default is A4 paper, portrait, using millimeters for units

  doc.setFontSize(16);
  doc.text("Quote", 14, 10);

  let total = 500
  const tableData = [
      ['Service', 'Qty', 'Unit Price', 'Total'],
      ['Service 1', 2, 10, 20],
      // ...
  ];

  // Créer un tableau
  doc.autoTable({
      head: tableData[0],
      body: tableData.slice(1)
  });

  doc.text(`Total : ${total} MAD`, 100, 200);
  doc.save('Quote.pdf');
}

export default Quote


/**
<Uploader />
 */