import React, {useState, useEffect} from 'react'
import {meSplitter} from '../shared/meLayout'
import {CheckControl} from '../shared/InputControl'
import { Timers, Focused } from '../Timer/Timers'
import TreeView from "../meTree/TreeView"
import TableView from '../meTable/TableView'
import {Read} from '../shared/Api'

// https://2us.ma/images/Sync-2.jpeg

const Home = ({lang, connected, searsh}) => {
  const container = 'HomeContainer'
  const [message, Message] = useState('')
  const [cell, Cell] = useState({entity:'', id:0, field:'', value:''})
  const [node, Node] = useState({entity:'', id:0, parent:0})
  const [clip, Clip] = useState({entity:'Clip', id:0})
  const [layouts, Layouts]= useState([])

  const splitter = new meSplitter("Side", container)
  
  useEffect(()=>{
    Read(`/Layout/${container}`,Layouts,null,null)
  },[])

  useEffect(()=>{
    splitter.InitStyle(layouts)
  },[layouts])

  const onNode = (item) => {
    //alert(`entity=${item.entity}, id=${item.id}, parent=${item.parent}`)
    
    Node(item)
  }

  const onCell = (item) => {
    //console.log(cell)
    Cell(item)
  }

  useEffect(()=>{
    if(node.entity==='Page'){
      Clip({...clip, id:node.id})
    }
    else {
      Clip({...clip, id:0})
    }

  },[node])

        return (
          connected ? 
      
    <div className="Sides" id={container}
          onMouseDown={e=>splitter.mouseDown(e)} 
          onMouseMove={e=>splitter.mouseMove(e)} 
          onMouseUp={e=>splitter.mouseUp(e)}            
          >

          <div className="side" id="SideTree" style={{width: '25%', bottom:0, verticalAlign:'bottom'}}>
            <TreeView request={'sync/nodes'} onChange={onNode} Message={Message} lang={lang} />
          </div>

          <div className="side" id="SideDetail" style={{width: '55%'}}>
            <TableView node={node} onChange={onCell} onParent={onNode} Message={Message} />
          </div>

          <div className="side" id="SideTimer" style={{width: '20%'}}>
            <h2>Synchronize...</h2>
            <CheckControl name='Connected' value={true} />
            <Timers element={0}/>
          </div>
    </div>

: 
<div>
<h2 style={{color:'red'}}>Synchronize...</h2>
<CheckControl name='Connected' value={false}  />
</div>

        )
    }

    export default Home

