import React, {useState, useEffect, useRef} from 'react'
import {Route, Routes, BrowserRouter} from 'react-router';
import './App.css';
import Home from './components/Home/Home';
import {Site} from './components/site/Site';
import {Drive} from './components/drive/Drive';
import {Worker} from './components/worker/Worker';
import {Detail} from './components/detail/Detail';
import {Step} from './components/step/Step';
import Header from './components/Home/Header';
import Footer from './components/Home/Footer';
import Global from './components/Home/Global';
import Landing from './components/Landing/Landing';
import Profile from './components/Profile/Profile';
import Quote from './components/Quote/Quote';

const global = new Global()

function App() {
  const app = 'Synchronize'
  const service=2
  const [connected, Connect] = useState(false)
  const [search, Search]  = useState("")
  const route = useRef("/")
  const [role, Role] = useState(2)
  const [infos, Infos] = useState([])
  const [plan, Plan] = useState(null)
  const [lang, Lang] = useState('en')
  
  useEffect(() => {
    Connect(global.connected())
/*
    const store = localStorage.getItem(app)
    if(store) {
        const value = JSON.parse(store)
        Connect(value?.connected)
        Infos(value?.message?.split('-'))
    }
*/
  },[])


  useEffect(()=>{ 
    if(plan) {       
      //alert(plan.Name)
    }
  },[plan])


  return (

connected ?
  <BrowserRouter>
  <Header app={app} connected={connected} onSearch={Search} />
  <div className='container'>
      <Routes>    
        <Route path='/' element={ <Home app={app} lang={lang} connected={connected} search={search} />} exact />
        <Route path='/site' element={ <Site global={global} search={search} />} />
        <Route path='/drive' element={ <Drive global={global} search={search} />} />
        <Route path='/worker' element={ <Worker global={global} search={search} />} />
        <Route path='/detail' element={ <Detail global={global} search={search} />} />
        <Route path='/step' element={ <Step global={global} />} />
        <Route path='/profile' element={ <Profile connected={connected} />} />
      </Routes>      
  </div>
  <Footer show={true}/>
</BrowserRouter>

:
  <BrowserRouter>
  <div className='container'>
    <Routes>    
      <Route path='/' element={ <Landing service={service} lang={lang} onLang={Lang} onPlan={Plan} />} exact />
      <Route path='/quote' element={ <Quote service={service} lang={lang} plan={plan} />} /> 
    </Routes>    
  </div>
  </BrowserRouter>

  )
}

export default App;


/*
 {  global.connected() ? 
  <Navigate to='/drive' />
: 
  <Navigate to='/' />
}

 */