import React, { useState } from 'react';
import './Profile.css';
import TableView from '../meTable/TableView';
import {ToNotify} from 'fast_inon';

const Profile = ({connected}) => {
    const node = {entity:'Profile', id:1, parent:1}
    const [message, Message] = useState('')

    const onCell = (item) => {
        //alert(`node=${item.Value}`)
    }

return (

<div className="Home" id="MainContainer">

{ connected ?  
  <div className='CollapseTab'> 
    <TableView request={'account'} node={node} onChange={onCell} />
  </div>
: <h2>Login</h2> }

<ToNotify 
    message={message}
    onClose={()=>{Message('')}}
/>

</div>
        )
}

const ProfileMenu = ({app, profile}) => {

  const onLogout = (e) => {
      //alert('remove localStorage')
      let store = localStorage.getItem(app)
      if(store) {
        localStorage.removeItem(app)
        window.location.reload()
      }
  }

  return (
    <div id="ProfileItems" style={{visibility:profile}}>
      <ul>
      <li><a href="/profile">Profile</a></li>
      <li><a href="https://2us.ma" rel="noreferrer" target="_blank">Home</a></li>
          <li><a href="https://tech2us.ma" rel="noreferrer" target="_blank">Tech2us</a></li>
          <li><a href="https://digitalservices.ma/apps/sync2us/publish.htm" rel="noreferrer" target="_blank">SuncApp</a></li>
          <li><a href="/setting">Settings</a></li>
          <li><a href="https://tech2us.ma/#contact" rel="noreferrer" target="_blank">Tech2us</a></li>
      </ul>
      <div className="divider"></div>
      <ul>
          <li style={{display:'block'}} onClick={onLogout} >Sign Out</li> 
      </ul>
    </div>
  )
}


export default Profile
export {ProfileMenu}
