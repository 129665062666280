
import React, { useState, useEffect } from 'react';
import './ContactInfo.css';
import {contact} from '../Config/app';
import {Delay, Cookie, Fetch} from '../shared/Api';
import {T} from '../Config/Lang/switcher';

const IsEmail = (value) => {
    // eslint-disable-next-line no-useless-escape
    const EMAIL_REGEX= /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/
    if(value.length>5 && EMAIL_REGEX.test(value)) {
        return true
    }
}

const ContactInfo = ({service, lang, onConfirm}) => {
    const [etat, Etat] = useState('') // to debug !
    const [ready, Ready] = useState(false) // to debug !
    const [info, Info] = useState({mode:0, name:'', email:'', code:'', message:'Quote',service})
    const t = (value, key='contact') => T(lang, key, value)

    const onMode = (add=1) => {
        info.mode += add
        Info({...info})
    }

  const onSend = (e) => {
    document.cookie = `meContact=${info.name}|${info.email}`
    Fetch('/service/message', info) //e.target)
    .then(res => {
        //  alert(`${res.status} - ${info.mode}`)
        onMode()
    })
    .catch(err => {
        if(err.status===208) {
            setColor(contact.theme.green)
            Delay(3000)
            onMode()
        }
        else {
            onMode(-info.mode)
        }
    })
}

const setColor = (element, theme) => {
    if(element && theme) {
        element.style.color = theme.c
        element.style.borderColor = theme.bc
        element.style.backgroundColor = theme.bgc
    }
}

const isBlue = (element) => {
    let parent = element.parent
    //alert(parent.id)
    return parent?.style.color === contact.theme.blue.c ? 'visible' : 'hidden'
}

const onChange = (e) => {    
    info[e.target.name]=e.target.value
    Info({...info})
}

  // to debug
  useEffect(() => {
    //Etat(`info: s=${info.service} m=${info.mode} n=${info.name} e=${info.email} c=${info.code} message=${info.message}`)

    const element = document.getElementById("contact-info") 
    if(element) {
        if(info.mode===0) {
            let cs = Cookie('meContact')?.split('|')   
            //alert(cs)           
            if(cs?.length===2 && IsEmail(cs[1])) {
                Info({...info, name:cs[0], email:cs[1], mode:1})
                //alert(`cs=${cs}`) 
            }
            else {
                onMode()
            }
            
        }
        else if(info.mode===1) {
            let inputs = info.name.length>=3 && IsEmail(info.email)
            if(inputs){
                setColor(element, contact.theme.blue)
            }
            else {
                setColor(element, contact.theme.red)
            }
            Ready(inputs)
        }
        else if(info.mode===2) {
            setColor(element, contact.theme.orange)

            if(info.code.length===6) {
                onSend(element)
            }
        }
        else if(info.mode===3) {
            //element.classList.contains('confirming') ?  element.classList.remove('confirming') : element.classList.add('confirming')
            //alert(`info=${info.mode} ${info.name} ${info.email}  ${info.service}`)
            Fetch('/service/confirmed', info) //e.target)
            .then(response => response.json())
            .then(
                (result)=>{
                    element.style.backgroundColor='transparent'
                    element.style.color='darkblue'
                    element.style.borderWidth=0
                    
                    onConfirm({ed:true, id:result.id, account:result.account, email:info.email, name:info.name})
                    onMode()
                },
                (error)=>{
                    Etat(`Error1=${error.status} n=${info.name} e=${info.email} message=${error.message}`)
                }
            )
            .catch(error => {
                Etat(`Error2=${error.status} message=${error.message}`)
            })
        }
    }
  }, [info]); 

  return (
    <div id="contact-info">
    { info.mode===1 ?
        <>
            <p>{t('confirm')}</p>
            <input name="name"  onChange={(e) => onChange(e)} type="text"  id="name"  placeholder="Full Name" value={info.name} required />
            <input name="email" onChange={(e) => onChange(e)} type="email" id="email" placeholder="Email" value={info.email} required />

            <button onClick={(e) => onSend(e)} value={info.code} style={{visibility: ready ? 'visible' : 'hidden'}} className="Button">Confirm</button>
        </>
        :
        info.mode===2 ? 
        <>
            <p>{t('confirm')}</p>
            <input name="code" onChange={(e) => onChange(e)} 
                placeholder="Code" type="text" id="code" required className='inputCode' />
        </>
        :
        info.mode===4 ?
        <div className="Address">
        <h5>{info.name}</h5>
        <p>{info.email}</p>
        </div>
        :
        <div className="row">
            <h2>? Invalid ?</h2>
        </div>
        }
    {  etat.length>0 ? <p>{etat}</p> : null }
    </div>
  );
};

export default ContactInfo;
export {IsEmail};

