
import {enConfig} from './en';

export const arConfig = {
    ...enConfig.base,
    bismi:'بِسۡمِ ٱللهِ ٱلرَّحۡمَـٰنِ ٱلرَّحِيمِ',
    salaam:'ٱلسَّلَامُ عَلَيْكُمْ',
    
    home:{
        title : 'تزامن',
        about: 'مزامنة الخدمة من المحلي إلى السحابة',
        connected: 'يجب أن تكون مسجلا'
    },
    
    blog: {
        ...enConfig.blog,
        title :'أكثر المقالات إثارة للاهتمام',
    },

    header:{
        signup : "التسجيل",
        signin : "الدخول",    
        signout : "خروج"
    },
    
    plan:{
        title :'الاشتراكات والخطط',
        order:'اطلب الآن',
        extend : 'أحصل على مزيد من حجم المزامنة',
        Extend : 'تمديد الخطة',
        credit : 'لديه رصيد'
    },

    quote: {
        title:'اقتباس شخصي',
        action : 'إرسال الطلب',
        order:[
            "يرجى تأكيد طلبك",
            "عند التأكيد، ستتلقى الخطوات التي يجب اتباعها للدفع",
            "شكرًا لك!"
        ],
        unselected :'? خطة غير محددة ?',
        valid: 'صالح حتى ',
    },

    contact:{
        title:'تواصل معنا',
        quote:'تأكيد الاتصال',
        message:'يرجى ملء النموذج أدناه لإرسال بريد إلكتروني إلينا',
        confirm:'تأكيد معلومات الاتصال',
        content:'يرجى ملء الكود الذي أرسلناه لك عبر البريد الإلكتروني لتأكيده',
        recipient:"يرجى تأكيد معلومات المستلم.",
        feedback : 'لقد تلقينا رسالتك، وسنقوم بالرد عليك في أقرب وقت ممكن',
    },

    getApp : {
        name : 'تحميل [Sync App]',
        info : 'تحميل التطبيق',
        label : 'تحميل التطبيق',
    }


}

