import React from 'react'
import {Form, Button} from 'react-bootstrap';

const SaveControl = ({entity,mode}) => {

    return (
        mode>0 && 
        <Form.Group style={{justifyContent:"center", alignItems:"center", display: "flex"}}>
            <Button variant="success" type="submit" style={{width:"auto", fontSize:"1.2rem"}}>
            <i className="fa fa-save"></i> { entity.includes('/') ? entity.slice(entity.indexOf('/')+1) : entity}
            </Button>
        </Form.Group>
    )
}

export default SaveControl
