import { act } from 'react'
import {base, plan as _plan} from '../app'

export const enConfig = {
    ...base,
    salaam :'Salaam',
    header:{
        joinus : "Join us",
        signin : "Login",    
        signout: "Exit"
    },
    
    home:{
        title:'Synchronize',
        about:'Synchronize Service from Local to Cloud',
        connected: 'You must be registered'
    },
    
    blog:{
        title :'Most Interesting Articles',
        read:'Read more...'
    },
            
    plan:{
        title :'Subscription & Plans',
        order :'Order Now',
        extend : 'Get more sync volume',
        Extend : 'Extend Plan',
        credit : 'He has credit'
    },

    quote: {
        title:'Personalized Quote',
        action : 'Submit Order',
        order:[
            "Please confirm your order",
            "When confirmed you will receive the steps to follow for payment",
            "Thank you!"
        ],
        unselected :'? Unselected Plan ?',
        valid: 'Valid until ',
    },
    
    contact:{
        title:'Get In Touch',
        message:'Please fill out the form below to send us an email',
        confirm:'Contact Confirming',
        content:'Please fill out the code we sent you by email to confirm it',
        recipient:"Please confirm the recipient's information.",
        feedback : 'We have received your message, we will get back to you as soon as possible',
    },
 
    getApp : {
        name : 'Download Sync App',
        info : 'Download Sync App',
        label : 'Download Sync App'
    }
}
