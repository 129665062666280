import {Component} from 'react'
import {base} from '../Config/app';
import Api, {Cookie} from '../shared/Api'
export default class SignApi extends Component{

    constructor(props){
        super(props)
        this.data = {service:'', email:Cookie('meEmail')??'', code:'', phone:Cookie('mePhone')??'', country:Cookie('meCountry')??1, from:1, mode:1}

        this.apiUrl = Api.defaults.baseURL+ "/signin"

        this.headers = {            
            'Accept':'application/json',
            'Content-Type':'application/json'
        }
        this.message=""
    }
    
    static message  

    isEmailReady(value) {

        if(value.length>5 && value.includes("@") && value.includes(".")) {
            const emailRegex = /\S+@\S+\.\S+/;
            if (emailRegex.test(value)) {
                this.data.email = value
                return true
            }
        }
        return false
    }

    isDataReady(from) {

        this.data.from=from

        if(this.data.mode===1) {
            if(from===2 && this.data.phone.length===10)
                return true
            else if(from===1 && this.isEmailReady(this.data.email))
                return true

        }
        else if(this.data.mode===2 && this.data.code.length===5) {
            return true
        }
        return false
    }

    isCode() {
        //const num = /^\d+$/;
        if(this.data.mode===2 && this.data.code.length===5) {
            return true
        }
        return false
    }


    async POST(onMode) {

        console.log('POST data :', this.data)       
        let response = await fetch(this.apiUrl, {
            method:'POST',
            headers:this.headers,
            body:JSON.stringify(this.data)
        })
        //console.log('POST response',response)
        if(response.ok){
            let result = await response.json()
            //console.log('POST result',result)
            if(result?.message) {
                this.message=result.message

                if(this.message.includes("Salaam"))// && result.role)
                {
                    if(this.data.mode===1) {
                        this.data.mode=2
                    }
                    else if(this.data.mode===2 || this.data.from===3) {
                        localStorage.setItem(base.name, JSON.stringify({
                            connected:true, ...result}   
                        ))
                        this.data.mode=3
                    }
                    onMode(this.data.mode)
                    return true
                }
            }
            else {
                this.message="Error api: result message is null?"
            }
        }
        else {
            this.message="Error api: response invalid?"
        }
        //console.log('POST message',this.message)
    }

}

