import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  padding: 0.1rem 1rem;
  height: 1.5rem;
  font-size: 0.9rem;
  text-align: center;
  background-color: #eee; /* Light gray background */
  color: #336;
  position: fixed;
  width: 100%;
  bottom: 0;
`;

const Copyright = styled.p`
  margin: 0;
`;

const Footer = ({show, lang='en'}) => {
  return (
    show && 
    <FooterContainer >
      <Copyright>&copy; {new Date().getFullYear()} Rais Digital Services</Copyright>
    </FooterContainer>
  );
};

export default Footer;
