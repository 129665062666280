// App.js
import React from 'react';
import './Landing.css';
import Header from './Header';
import Articles from './Articles';
import About from './About';
import Slider from './Slider';
import Plans from '../Plan/Plans'
import Contact from './Contact';
import Footer from './Footer';
import GetApp from './GetApp';

function Landing({service, lang, onLang, onPlan}) {
  return (
    <div className="App">
      <Header lang={lang} onLang={onLang} />
      <Slider  service={service} lang={lang} />
      <Plans service={service} lang={lang} onPlan={onPlan} />
      <Articles service={service} lang={lang} />
      <Contact service={service} lang={lang} />
      <GetApp service={service} lang={lang} />
      <About service={service} lang={lang} />
      <Footer lang={lang} />
    </div>
  );
}

export default Landing;

