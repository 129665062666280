

const base = {
    logo: 'logo.png',
    icons: 'https://2us.ma/content/icons/',
    title: 'sync.2us.ma',
    name: 'Synchronize',
}

const plan = {
    icon:'sync-1.png',
    link:'/order'
}

const contact = {
    theme: {
        red : {c:'rgb(102,0,0)', bc:'rgb(153,0,0)', bgc:'rgba(234,153,153,0.5)'},
        blue : {c:'rgb(7,55,99)', bc:'rgb(11,83,148)', bgc:'rgba(159,197,232,0.5)'},
        orange : {c:'rgb(120,63,4)', bc:'rgb(180,95,6)', bgc:'rgba(249,203,156,0.5)'},
        green : {c:'rgb(39,78,19)', bc:'rgb(56,118,29)', bgc:'rgba(182,215,168,0.5)'}
    }
}

const quote = {
    logo:'https://digitalservices.ma/logo.png',
    //logo:'https://tech2us.ma/images/logo.png',
    bc : ['#ccc', 'rgb(217,234,211)', 'rgb(208,224,227)', 'rgb(217,210,233)'],
    th : [{width:'55%',name:'Service'}, {width:'15%',name:'Quantity'}, {width:'15%',name:'Unit Price'}, {width:'15%',name:'Price'}],
}

const icons = {
    add : base.icons+'add.png',
    reload : base.icons+'reload.png',
    minimize : base.icons+'minimize.png',
    maximize : base.icons+'maximize.png'
}

const getApp = {
    image : 'https://tech2us.ma/images/SynchronizeApp-25.1.11.png',
    url : 'https://tech2us.ma/apps/SetupSynchronize-25.1.8.msi'
}

export {base, plan, contact, quote, icons, getApp}
