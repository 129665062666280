import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
position: relative;
  max-width: 1024px;
  margin : auto;
  padding: 1rem 2rem;
  text-align: center;
  background-color: #eee; /* Light gray background */
  color: #336;
  height: 6rem;
`;

const Copyright = styled.p`
  position: absolute;
  font-size: 0.8rem;
  margin: 0;
  right: 1rem;
  bottom: 0;
`;

const Footer = ({lang='en'}) => {
  return (
    <FooterContainer >
      <Copyright>&copy; Rais Digital Services {new Date().getFullYear()}</Copyright>
    </FooterContainer>
  );
};

export default Footer;
