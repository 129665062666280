import styled from 'styled-components';

const Button = styled.button`
    border: 1px solid #616A94;
    border-radius: 50px;
    padding: 15px 30px;
    margin: 10px;
    text-decoration: none ;
    text-align: center;
    color: white;
    background-color: darkblue;
    transition: 0.3s;
    font-size: 1em;
    cursor: pointer;
    outline: none;
    
    &:hover {
        color: white;
        font-weight: bold;
        background-color: blue;
        border : 6px solid silver ;
    }
`;

export {Button} ;