import {Component} from 'react'
import {base} from '../Config/app';

export default class Global extends Component{

    constructor(props){
        super(props)

        this.store=null     

        this.headers = {            
            'Accept':'application/json',
            'Content-Type':'application/json'
        }
        
        this.parseStorage()

        this.logo='https://digitalservices.ma/images/logo.jpg'

        //this.apiUrl='http://localhost:2022/' // Dev
        this.apiUrl='https://api.tech2us.ma/' // Prod

        this.state = {items:[], loading:false}
    }

    connected() {
        if(this.store && this.store.connected)
            return true

        return false
    }


    toString(datetime){
        const meDate = (datetime) ? new Date(datetime) : new Date() ;
        return meDate.toISOString().split('T')[0];
    }

    //bind the context:
    parseStorage() {
        this.store = JSON.parse(localStorage.getItem(base.name))
        //console.log('parseStorage',this.store)
        if(this.store && this.store.connected)
        {
            this.headers = {
                Authorization: `Bearer ${this.store.token}`,
                ...this.headers                
            }
            //console.log('headers',this.headers)
        }

    }
    
    async GET(relativeUrl) {
        this.state ?? this.setState({loading:true})
        let request=this.apiUrl+relativeUrl
        //console.log('request', request)
        let response = await fetch(request, {
            method: "GET",
            headers: this.headers
        })
        if(response.ok){
            let data = await response.json()
            this.state ?? this.setState({items:data, loading:false})
            //console.log('response', data)
            return data
        }
        else {            
            throw new Error(`GET : ${response.status} ?`)
        }
    }

    async GetItem (controller, id) {

        this.state ?? this.setState({loading:true})

        let request = `${this.apiUrl}${controller}/${id}`
        //alert(request)
        let response = await fetch(request, {
            method: "GET",
            headers: this.headers
        })
        if(response.ok){
            let data = await response.json()
            this.state ?? this.setState({loading:false})
            //console.log('GetItem', data)
            return data
        }
        else {
            throw new Error(`GetItem : ${response.status} ?`)
        }
    }


    async DELETE(controller, id)
    {
        let request = `${this.apiUrl}${controller}/${id}`

        if(window.confirm('Are you sure to delete this item?')){
            let response = await fetch(request, {
                method:'DELETE',
                headers: this.headers,
                body:JSON.stringify({
                    token:this.store.token
                })
            })
            //console.log('response', response)
            if(!response.ok)
                throw new Error(`DELETE : ${response.status} ?`)
        }
    }

    toDay = () => {
        const meDate = new Date();
        return meDate.toISOString().split('T')[0]
    }

    Delay = ms => new Promise(res => setTimeout(res, ms))

}

//export default Global