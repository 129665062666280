// Hero.js (example)
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {getApp} from '../Config/app';
import { Button } from '../shared/Styled';
import {T as LS} from '../Config/Lang/switcher';

const GetApp = ({service, lang, connected=true}) => {

  const T = (value, key='getApp') => LS(lang, key, value)

  useEffect(() => {

  }, [service, lang]);

  const onDownload = () => {
    if(connected) 
    {
        const link = document.createElement('a'); 
        link.href = getApp.url; 
        link.download = getApp.url.substring(getApp.url.lastIndexOf('/') + 1); 
        document.body.appendChild(link); 
        link.click(); 
        document.body.removeChild(link);
    }
    else 
    {
        alert(T('connected', 'home'))
    }
}
  
  return (
    <GetAppContainer id="GetApp">
        <div className="col-md-4" >
          <a target="_blank" rel="noopener noreferrer" href={getApp.url}>
              <img style={{width:'100%'}} src={getApp.image}  />
          </a>
        </div>
        <div className="col-md-4">
          <a style={{textDecoration:'none'}} target="_blank" rel="noopener noreferrer" href={getApp.url}>
            <h2>{T('name')}</h2>
          </a>
        </div>
        <div className="col-md-4">
          <Button onClick={()=>onDownload()} style={{height:'6rem'}}>{T('label')}</Button>
        </div>
    </GetAppContainer>
  );
};


const GetAppContainer = styled.div`
  position: relative;
  max-width: 1024px;
  margin: 2rem auto;
  height: 12rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const GetAppImage = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  top:0;
  left:0;

`;

export default GetApp;

